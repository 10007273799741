import * as React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { useSiteMetadata } from "../hooks/defaults/Use-SiteMetadata";
import Cta from "../components/CTA/CTAMain";
import Layout from "../components/Defaults/Layout";
import MainArea from "../components/includes/MainAreas";
import Seo from "../components/seo";
var slugify = require("slugify");

const Areas = () => {
	const data = useStaticQuery(graphql`
		query AllGovAreas {
			allBranchesCsv {
				allGOVareas: group(field: { local_government_area: SELECT }) {
					fieldValue
					field
				}
			}
		}
	`);

	return (
		<>
			<Layout>
				<Cta background={true} />
				<MainArea />
			</Layout>
		</>
	);
};
export const Head = () => {
	const { mainArea, mainService, phoneNum } = useSiteMetadata();
	return (
		<Seo
			title={`Areas We Cover in ${mainArea} for All ${mainService} Services`}
			description={`Check out all the Areas we cover in ${mainArea}. We will have you covered for all you ${mainService} needs. Call us now on ${phoneNum}!`}
		/>
	);
};

export default Areas;
